// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-templates-default-page-index-js": () => import("./../../../src/templates/default-page/index.js" /* webpackChunkName: "component---src-templates-default-page-index-js" */),
  "component---src-templates-events-page-index-js": () => import("./../../../src/templates/events-page/index.js" /* webpackChunkName: "component---src-templates-events-page-index-js" */),
  "component---src-templates-publications-page-index-js": () => import("./../../../src/templates/publications-page/index.js" /* webpackChunkName: "component---src-templates-publications-page-index-js" */),
  "component---src-templates-talks-page-index-js": () => import("./../../../src/templates/talks-page/index.js" /* webpackChunkName: "component---src-templates-talks-page-index-js" */)
}

